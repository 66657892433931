var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (bgsu, config, css) {
      pug_mixins["link"] = pug_interp = function(href, text, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cp\u003E\u003Ca" + (pug.attr("href", href, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fp\u003E";
};
pug_html = pug_html + "\u003Cfooter" + (pug.attr("class", pug.classes([css.template_footer], [true]), false, true)) + "\u003E";
if (config.note && config.note.footer) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.template_note], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.template_container], [true]), false, true)) + "\u003E" + (null == (pug_interp = config.note.footer) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.template_footer_top], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.template_container], [true]), false, true)) + "\u003E\u003Cdiv\u003E\u003Cp\u003E\u003Csvg xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" viewBox=\"0 0 392.4 110.16\"\u003E\u003Cuse xlink:href=\"#bgsu_template_logo\"\u002F\u003E\u003C\u002Fsvg\u003E\n\u003C\u002Fp\u003E\u003Cp\u003E\u003Cstrong\u003EBowling Green State University\u003C\u002Fstrong\u003E\u003Cbr\u003EBowling Green, Ohio\n\u003Cbr\u003E43403-0001\u003C\u002Fp\u003E";
if (config.help) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.template_footer_top_help], [true]), false, true)) + "\u003E";
if (config.help.heading) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = config.help.heading) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
}
if (config.help.href) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", config.help.href, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = config.help.text || config.help.href) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.template_footer_top_links], [true]), false, true)) + "\u003E";
bgsu = 'https://www.bgsu.edu/'
pug_mixins["link"](
                    bgsu + "accessibility.html",
                    "Accessibility including events"
                );
pug_mixins["link"](
                    bgsu + "academics.html",
                    "Academics",
                    "BGSU Academics"
                );
pug_mixins["link"](
                    bgsu + "a-z-links.html#A-Z",
                    "A-Z Links"
                );
pug_mixins["link"](
                    "https://services.bgsu.edu/directorySearch/search.htm",
                    "Campus Directory"
                );
pug_mixins["link"](
                    bgsu + "institutional-effectiveness/" +
                        "institutional-effectiveness/" +
                        "important-consumer-information.html",
                    "Consumer Information"
                );
pug_mixins["link"](
                    bgsu + "employment.html",
                    "Employment",
                    "BGSU Employment Resources"
                );
pug_mixins["link"](
                    bgsu + "faculty-staff.html",
                    "Faculty/Staff",
                    "BGSU Faculty/Staff Resources"
                );
pug_mixins["link"](
                    bgsu + "firelands.html",
                    "Firelands",
                    "BGSU Firelands"
                );
pug_mixins["link"](
                    bgsu + "library.html",
                    "Libraries"
                );
pug_mixins["link"](
                    bgsu + "news/online-media-newsroom.html",
                    "Media Resources"
                );
pug_mixins["link"](
                    bgsu + "equity-diversity-and-inclusion/" +
                        "e-o-compliance.html",
                    "Nondiscrimination"
                );
pug_mixins["link"](
                    bgsu + "families.html",
                    "Parents and Families"
                );
pug_mixins["link"](
                    bgsu + "students.html",
                    "Students",
                    "BGSU Students Resources"
                );
pug_mixins["link"](
                    bgsu + "its.html",
                    "Technology Support"
                );
pug_mixins["link"](
                    bgsu + "title-ix.html",
                    "Title IX Resources"
                );
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.template_footer_report], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.template_container], [true]), false, true)) + "\u003E\u003Cdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.template_footer_report_title], [true]), false, true)) + "\u003ESee It. Hear It. Report It.\u003C\u002Fdiv\u003E\u003Cp\u003EIt is the responsibility of each person within the\nuniversity community to take action when an issue or\nconcern arises.\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Ca href=\"https:\u002F\u002Fwww.bgsu.edu\u002Freport-incident.html\"\u003EReport concerns or information of alleged misconduct\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ffooter\u003E";
    }.call(this, "bgsu" in locals_for_with ?
        locals_for_with.bgsu :
        typeof bgsu !== 'undefined' ? bgsu : undefined, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined));
    ;;return pug_html;};
module.exports = template;