// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgsu_toggle_Zanuc[aria-hidden=true]{display:none}.bgsu_toggle_controls_EcTjK{display:inline-block;margin:0;padding:0;background:rgba(0,0,0,0);border:0;color:inherit;font:inherit;pointer-events:none;width:auto}.bgsu_toggle_controls_EcTjK[aria-controls]{cursor:pointer;pointer-events:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `bgsu_toggle_Zanuc`,
	"toggle_controls": `bgsu_toggle_controls_EcTjK`
};
export default ___CSS_LOADER_EXPORT___;
